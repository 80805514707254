<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    height="75"
    class="grey lighten-5"
  >
    <v-icon
      @click="toggleDrawer"
      class="mr-6"
    >
      mdi-menu
    </v-icon>

    <v-toolbar-title
      class="product-sans"
      style="font-size:1.5625rem"
      v-text="alias[$route.name] ? alias[$route.name] : $route.name "
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2 product-sans"
      style="letter-spacing: 1.5px"
      min-width="0"
      text
      outlined
      @click="$router.push({ name: 'User Profile' })"
    >
      <span
        v-if="$vuetify.breakpoint.smAndUp"
      >
        {{ $store.state.User.Email }}
      </span>
      <v-icon
        :right="$vuetify.breakpoint.smAndUp"
      >
        mdi-account
      </v-icon>
    </v-btn>

    <div
      v-if="false"
    >
      <v-btn
        class="ml-2"
        min-width="0"
        text
        to="/"
      >
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <v-list
          :tile="false"
          nav
        >
          <div>
            <app-bar-item
              v-for="(n, i) in notifications"
              :key="`item-${i}`"
            >
              <v-list-item-title v-text="n" />
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib';

// Utilities
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DashboardCoreAppBar',

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => h(VListItem, {
              attrs: this.$attrs,
              class: {
                'black--text': !hover,
                'white--text secondary elevation-12': hover,
              },
              props: {
                activeClass: '',
                dark: hover,
                link: true,
                ...this.$attrs,
              },
            }, this.$slots.default),
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      'You\'re now friends with Andrew',
      'Another Notification',
      'Another one',
    ],
    alias: {
      'Customer Index': 'Viewing all customers',
      'Customer Profile': 'Viewing a customer',
      'Customer Journey': 'Viewing a customer',
      'New Lead': 'Adding a lead',
    },
  }),

  computed: {
    ...mapState({ drawer: 'Theme/drawer' }),
  },

  methods: {
    ...mapMutations({
      setDrawer: 'Theme/SET_DRAWER',
    }),
    toggleDrawer() {
      this.setDrawer(!this.$store.state.Theme.drawer);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
